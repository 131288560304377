@use 'sass:math';

@import 'Styles/includes';

.GravityFormComp {
    $root: &;

    &__Wrap {
        @include wrap(d);
    }

    &__Title {
        // For overriding gutenberg style
        #{$root} #{$root}__Wrap & {
            @include textstyle(h3);
        }

        margin-top: 0 !important;
    }

    &__Description {
        @include textstyle(body-regular);

        margin-bottom: 24px;

        #{$root}__Title + & {
            margin-top: 16px;
        }
    }

    &__Form {
        max-width: map-get($maxwidths, text);

        button[type='submit'] {
            @extend %form__submit;
        }

        #{$root}__Title + &,
        #{$root}__Description + & {
            margin-top: 24px;
        }
    }

    &__Message,
    :global(.form-field .html-content) {
        @extend %richtext;

        margin: 60px 0;
        padding: 24px;
        border: 1px solid $grey-30;
    }

    &__Error,
    :global(.form-error) {
        @extend %form__error;
    }

    &__Overlay {
        @extend %form__overlay;

        /* Before form has loaded */
        #{$root}__Form > .form-wrapper > & {
        }

        /* After form has loaded, when submitting */
        form .form-wrapper > & {
            position: fixed;
        }
    }

    &__Loader {
        @extend %form__loader;

        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        margin: auto;

        /* After form has loaded, when submitting */
        form .form-wrapper > #{$root}__Overlay > & {
            bottom: 60px;
        }
    }

    :global {
        .form {
            &-title {
                display: none;
            }

            &-description {
                display: none;
            }

            &-wrapper {
                position: relative;
                min-height: 240px;
            }

            &-fields {
                display: flex;
                flex-wrap: wrap;
                margin-left: -8px;

                @include media(M) {
                    margin-left: -16px;
                }
            }

            &-error {
                // @extend %form__error;

                margin-bottom: 40px;
                margin-top: 0;
            }

            &-field {
                @extend %form__field-wrapper;

                width: calc(100% - 8px);
                flex: 1 0 auto;
                margin-left: 8px;
                margin-top: 0 !important;

                @include media(M) {
                    width: calc(100% - 16px);
                    margin-left: 16px;
                }

                &.size-medium {
                    @include media(M) {
                        width: calc(60% - 16px);
                    }
                }

                &.size-small {
                    @include media(M) {
                        width: calc(40% - 16px);
                    }
                }

                .gsection_title {
                    @include textstyle(h3);

                    margin-bottom: 0;
                }

                .gf-label {
                    @extend %form__label;

                    &.hidden_label {
                        display: none;
                    }

                    &:empty {
                        display: none;
                    }
                }

                .group-label {
                    @extend %form__label;
                    @extend %form__label--group;

                    &.hidden_label {
                        display: none;
                    }
                }

                .html-content {
                    a {
                        color: $base-color;
                        text-decoration: underline;
                        transition: color $transition;

                        &:hover {
                            color: $purple;
                            text-decoration: underline;
                        }
                    }
                }

                .description {
                    @extend %form__message;
                }

                .error-message {
                    @extend %form__message;
                }

                > div {
                    @extend %form__field;
                }

                > .text,
                > .textarea,
                > .number,
                > .email,
                > .phone {
                    > input,
                    > textarea {
                        @extend %form__input;
                    }
                }

                > .website {
                    > input {
                        @extend %form__input;
                    }
                }

                > .fileupload {
                    > input {
                        @extend %form__input--checkbox; /* For hiding */
                    }

                    .fileUpload {
                        @extend %form__input--file;
                    }
                }

                &.error {
                    .gf-label,
                    .group-label {
                        @extend %form__label;
                    }

                    .group-label {
                        @extend %form__label--group;
                    }

                    > div {
                        > input {
                            @extend %form__input--error;
                        }
                    }

                    > .textarea {
                        > textarea {
                            @extend %form__input--error;
                        }
                    }

                    /* Different error icons if label inside or not */
                    > .text,
                    > .textarea,
                    > .number,
                    > .email,
                    > .phone {
                        textarea,
                        input {
                            @extend %form__error-bg;
                        }
                    }
                }

                > .date {
                    > .date,
                    > .gfield_date_dropdown {
                        width: calc(#{percentage(math.div(1, 3))} - 8px);
                        margin-left: 8px;

                        > .hide-label {
                            @extend %sr-only;
                        }
                    }

                    > .date {
                        > input,
                        > textarea {
                            @extend %form__input;
                        }
                    }
                }

                &.date-type-datefield > .date,
                &.date-type-datedropdown > .date {
                    display: flex;
                    margin-left: -8px;
                }

                /* Place label inside input if placeholder is missing
                    from some fields */
                &:not(.has-placeholder):not(.hidden-label) {
                    > .text,
                    > .textarea,
                    > .number,
                    > .email,
                    > .phone {
                        > input,
                        > textarea {
                            @extend %form__input--label-inside;
                        }

                        .gf-label {
                            @extend %form__label--inside;
                        }
                    }

                    &.error {
                        /* Different error icons if label inside or not */
                        > .text,
                        > .textarea,
                        > .number,
                        > .email,
                        > .phone {
                            .gf-label {
                                @extend %form__label--inside-error;
                            }
                        }
                    }
                }

                &.error > .date > .date {
                    textarea,
                    input {
                        @extend %form__error-bg;
                    }
                }

                &.filled:not(.has-placeholder):not(.hidden-label) {  /* When focused or has a value */
                    > .text,
                    > .textarea,
                    > .number,
                    > .email,
                    > .phone {
                        .gf-label {
                            @extend %form__label--inside-focus;
                        }
                    }
                }

                &.dropzone {
                    .dropzone {
                        @extend %form__input;
                        @extend %form__input--multi-file;
                    }
                }

                .checkboxes {
                    .checkbox label,
                    .gfield_consent_label {
                        @extend %form__label;
                        @extend %form__label--checkbox;
                    }

                    button {
                        @include button(tertiary);
                    }
                }

                .radios {
                    .radio {
                        label {
                            @extend %form__label;
                            @extend %form__label--radio;
                        }

                        &.other-choice {
                            display: flex;
                            gap: 8px;

                            input[type='text'] {
                                @extend %form__input;

                                flex: 1 1 auto;
                            }

                            label {
                                flex: 0 0 auto;
                                top: 4px;
                                padding-right: 0;

                                @include media(M) {
                                    top: 6px;
                                }
                            }
                        }
                    }
                }

                .radios,
                .checkboxes {
                    input[type='checkbox'] {
                        @extend %form__input--checkbox; /* For hiding */

                        &:checked + label {
                            @extend %form__label--checkbox-checked;
                        }

                        &:focus + label {
                            @extend %form__label--checkbox-focus;
                        }
                    }

                    input[type='radio'] {
                        @extend %form__input--radio; /* For hiding */

                        &:checked + label {
                            @extend %form__label--radio-checked;
                        }

                        &:focus + label {
                            @extend %form__label--radio-focus;
                        }
                    }
                }

                &.captcha > div {
                    /* Captcha is too wide, compensate for this in smallest
                        screens */
                    @media (max-width: 350px) {
                        transform: scale(.9);
                        transform-origin: left;
                    }
                }

                /* Hide anti-spam honeypot field */
                &.gform_validation_container {
                    display: none;
                }

                fieldset {
                    min-inline-size: auto;
                }
            }
        }
    }
}
