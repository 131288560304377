@use 'sass:math';

%richtext {
    @include textstyle(body-regular);

    position: relative;
    color: $base-color;
    font-family: $base-font;

    // Clear blocks that shouldn't be floatable (paragraphs and images)
    ol,
    ul,
    &.Gutenberg--MemberSurvey,
    .wp-block-sage-preamble,
    .preamble,
    .wp-block-buttons,
    blockquote,
    .iframe,
    .gallery,
    &.Gutenberg--CoreGallery,
    .wp-block-table,
    .wp-block-gallery,
    .wp-block-embed,
    .wp-block-sage-emphasis,
    .wp-block-sage-sender,
    &.Gutenberg--GravityFormComp,
    &.Gutenberg--Chart,
    &.Gutenberg--Newsletter,
    &.Gutenberg--CoreEmbed,
    &.Gutenberg--SacoLink,
    .wp-block-columns {
        clear: both;

        @include clearfix;
    }

    blockquote,
    .gallery,
    &.Gutenberg--CoreGallery,
    .wp-block-table,
    .wp-block-gallery,
    .wp-block-sage-sender,
    .statistics,
    &.Gutenberg--Chart,
    &.Gutenberg--CoreEmbed {
        @media print {
            break-inside: avoid;
        }
    }

    p,
    hr,
    ol,
    ul:not(.statistics):not(.gform_fields):not(.block-editor-block-variation-picker__variations),
    &.Gutenberg--MemberSurvey,
    .wp-block-sage-preamble,
    .preamble
    .wp-block-buttons,
    .wp-block-sage-sender,
    &.Gutenberg--SacoLink {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    &.Gutenberg--Chart {
        margin-top: 40px;
        margin-bottom: 40px;

        @include media(L) {
            margin-top: 64px;
            margin-bottom: 64px;
        }
    }

    // Other margins to compensate for item margins
    .statistics {
        margin-top: 40px;
        margin-bottom: 40px;

        @include media(SL) {
            margin-top: 40px;
            margin-bottom: 8px;
        }

        @include media(M) {
            margin-top: 40px;
            margin-bottom: 0;
        }

        @include media(L) {
            margin-top: 64px;
            margin-bottom: 24px;
        }
    }

    blockquote,
    .wp-caption,
    .wp-block-image,
    .iframe,
    .gallery,
    &.Gutenberg--CoreGallery,
    .wp-block-table,
    .wp-block-gallery,
    .wp-block-embed,
    .wp-block-sage-emphasis,
    &.Gutenberg--GravityFormComp,
    &.Gutenberg--Newsletter,
    &.Gutenberg--CoreEmbed,
    .wp-block-columns {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 24px;
        margin-bottom: 16px;

        + h2,
        + h3,
        + h4,
        + h5,
        + h6,
        + p {
            margin-top: 16px;
        }
    }

    h1,
    h2 {
        @include textstyle(h2);
    }
    h3 {
        @include textstyle(h3);
    }
    h4,
    h5,
    h6 {
        @include textstyle(h4);
    }

    strong,
    bold {
        font-weight: $heavy;
    }

    i,
    em {
        font-style: italic;
    }

    p,
    ul,
    ol,
    .wp-block-file,
    .gform_confirmation_message {
        a {
            color: $base-color;
            text-decoration: underline;
            transition: color $transition;

            &:hover {
                color: $purple;
                text-decoration: underline;

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-link;
                }
            }

            @include href-external {
                display: inline-block;
                position: relative;
                padding-right: 22px;

                &::before,
                &::after {
                    content: '';
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: 6px;
                    right: 0;
                    display: inline-block;
                    background: url('#{$assetsPath}img/external--grey.svg') no-repeat center center;
                    background-size: contain;
                    opacity: 1;
                    transition: opacity $transition;
                }

                &::after {
                    background-image: url('#{$assetsPath}img/external--purple.svg');
                    opacity: 0;

                    :global(.Theme--Arkitekturgalan) & {
                        background-image: url('#{$assetsPath}img/external--arkitekturgalan-blue.svg');
                    }
                }

                &:hover {
                    &::before {
                        opacity: 0;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }

            // !important is needed to make sure this has higher priority than external if both are added
            @include href-download {
                display: inline-block;
                position: relative;
                padding-left: 22px;
                padding-right: 0 !important;

                &::before,
                &::after {
                    content: '';
                    width: 16px;
                    height: 18px !important;
                    position: absolute;
                    top: 6px;
                    left: 0;
                    display: inline-block;
                    background: url('#{$assetsPath}img/download--grey.svg') no-repeat center center !important;
                    background-size: contain;
                    opacity: 1;
                    transition: opacity $transition;
                }

                &::after {
                    background-image: url('#{$assetsPath}img/download--purple.svg') !important;
                    opacity: 0;

                    :global(.Theme--Arkitekturgalan) & {
                        background-image: url('#{$assetsPath}img/download--arkitekturgalan-blue.svg') !important;
                    }
                }

                &:hover {
                    &::before {
                        opacity: 0;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    a {
        @media print {
            &::after {
                content: " [" attr(href) "] " !important;
                position: relative !important;
                display: inline !important;
                top: auto !important;
                bottom: auto !important;
                left: auto !important;
                right: auto !important;
                color: $base-color !important;
                background: none !important;
                opacity: 1 !important;
            }
        }
    }

    ol,
    ul:not(.statistics):not(.gform_fields):not(.blocks-gallery-grid):not(.block-editor-block-variation-picker__variations) {
        list-style: none;

        li {
            margin: 16px 0;
            position: relative;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul,
        ol {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul {
        @media print {
            list-style: initial !important;
            padding-left: 20px !important;
        }

        &:not(.statistics):not(.gform_fields):not(.blocks-gallery-grid):not(.block-editor-block-variation-picker__variations) {
            li {
                padding-left: 30px;

                &::before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    display: inline-block;
                    position: absolute;
                    top: 11px;
                    left: 0;
                    background: black;
                }

                @media print {
                    padding-left: 0px !important;
                }
            }
        }
    }

    ol {
        list-style-type: decimal;
        margin-left: 22px;

        li {
            padding-left: 8px;
        }
    }

    .has-text-align-center {
        text-align: center;
    }
    .has-text-align-right {
        text-align: right;
    }
    .has-text-align-left {
        text-align: left;
    }

    .has-small-font-size,
    .has-regular-font-size,
    .has-normal-font-size,
    .has-medium-font-size,
    .has-large-font-size,
    .has-larger-font-size,
    .has-huge-font-size {
        color: inherit;
        font-size: inherit;
        font-family: inherit;
    }

    blockquote,
    .wp-block-quote {
        max-width: 498px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 24px 16px;
        border: none;

        @include media(M) {
            padding: 0 30px 24px;
        }

        &::before {
            content: '';
            display: block;
            width: 29px;
            height: 26px;
            background: url('#{$assetsPath}img/quote--salmon.svg') no-repeat center center;
            background-size: contain;
            text-align: center;
            margin: 0 auto 16px;

            :global(.Theme--Arkitekturgalan) & {
                background-image: url('#{$assetsPath}img/quote--arkitekturgalan-orange.svg') !important;
            }
        }

        p {
            @include textstyle(h4);

            position: relative;
            padding: 0;
            margin: 16px 0 0;
            color: black;
            text-align: center;

            @media print {
                &::before {
                    @include textstyle(h2);

                    content: '“';
                }

                &::after {
                    @include textstyle(h2);

                    content: '”';
                }
            }
        }

        cite,
        &__citation {
            @include textstyle(body-small);

            margin-top: 20px;
            color: black;
            text-align: right;
            display: flex;
            justify-content: flex-end;
        }
    }

    .wp-block-columns {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + #{$gutter});
        margin-left: -$gutter;
        margin-bottom: -$gutter;

        @include media(M) {
            width: calc(100% + #{$gutterM});
            margin-left: -$gutterM;
            margin-bottom: -$gutterM;
        }

        .wp-block-column {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            margin-left: $gutter !important;
            margin-bottom: $gutter;
            width: calc(100% - #{$gutter});

            @include media(M) {
                margin-left: $gutterM !important;
                margin-bottom: $gutterM;
                width: calc(50% - #{$gutterM});
            }
        }

        &.alignwide {
            margin-left: -24px;
            margin-bottom: -24px;

            @include media(M) {
                width: calc(100% + 32px);
                margin-left: -32px;
                margin-bottom: -32px;
            }

            .wp-block-column {
                margin-left: 24px !important;
                margin-bottom: 24px;
                width: calc(100% - 24px);

                @include media(M) {
                    margin-left: 32px !important;
                    margin-bottom: 32px;
                    width: calc(50% - 32px);
                }
            }
        }

        &.alignfull {
            margin-left: -24px;
            margin-bottom: -24px;

            @include media(M) {
                width: calc(100% + 56px);
                margin-left: -56px;
                margin-bottom: -56px;
            }

            .wp-block-column {
                margin-left: 24px !important;
                margin-bottom: 24px;
                width: calc(100% - 24px);

                @include media(M) {
                    margin-left: 56px !important;
                    margin-bottom: 56px;
                    width: calc(50% - 56px);
                }
            }
        }
    }

    .wp-block-sage-preamble,
    .preamble {
        @include textstyle(preamble);
    }

    .wp-block-sage-emphasis {
        background: $salmon-30;
        padding: 24px;
        max-width: 618px;

        :global(.Theme--Arkitekturgalan) & {
            color: $arkitekturgalan-emphasis-color;
            background: $arkitekturgalan-emphasis;

            a {
                color: $arkitekturgalan-emphasis-color;

                @include href-external {
                    &::before,
                    &::after {
                        background-image: url('#{$assetsPath}img/external--arkitekturgalan-blue.svg');
                    }
                }

                // !important is needed to make sure this has higher priority than external if both are added
                @include href-download {
                    &::before,
                    &::after {
                        background-image: url('#{$assetsPath}img/download--arkitekturgalan-blue.svg') !important;
                    }
                }
            }
        }

        @include media(M) {
            padding: 44px 48px 48px;
        }

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }

        > p {
            margin: 16px 0;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 8px;

            + h2,
            + h3,
            + h4,
            + h5,
            + h6,
            + p {
                margin-top: 8px;
            }
        }
    }

    .wp-block-sage-sender {
        @include textstyle(body-small);

        display: flex;
        flex-wrap: wrap;
        max-width: 551px;

        &-image {
            flex: 0 1 auto;
            width: 23.6%;

            .wp-block-image {
                position: relative;
                margin: 0;
                height: 0;
                width: 100%;
                padding-bottom: 100%;
                overflow: hidden;

                img {
                    object-fit: cover;
                    object-position: 50% 50%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }

                figcaption {
                    display: none !important;
                }
            }
        }

        &-content {
            flex: 1 1 auto;
            width: 76.4%;
            padding: 0 0 0 24px;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                @include textstyle(h5);

                margin-bottom: 8px;
            }

            p {
                margin: 8px 0;
            }

            *:first-child {
                margin-top: 0;
            }

            *:last-child {
                margin-bottom: 0;
            }
        }
    }

    .statistics {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        margin-left: -20px;

        @include media(SL) {
            margin-left: -40px;
        }

        @include media(M) {
            margin-left: -60px;
        }

        @media print {
            margin-left: -40px;
        }

        li {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 0 1 auto;
            width: calc(50% - 20px);
            min-height: calc((100vw - 20px) / 2 * 1.3);
            margin-left: 20px;
            margin-bottom: -20px;

            @include media(SL) {
                width: calc(#{percentage(math.div(1, 3))} - 40px);
                margin-left: 40px;
                margin-bottom: 32px;
                min-height: 0;
            }

            @include media(M) {
                min-height: 220px;
                width: calc(#{percentage(math.div(1, 3))} - 60px);
                margin-left: 60px;
                margin-bottom: 40px;
            }

            @media print {
                width: calc(#{percentage(math.div(1, 3))} - 40px);
                margin-left: 40px;
                margin-bottom: 32px;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 0;
                padding-bottom: 130%;
                margin: auto;
                background: url('#{$assetsPath}img/shapeLeft--salmon30.svg') no-repeat center center;
                background-size: contain;
            }

            &:nth-child(even)::before {
                background-image: url('#{$assetsPath}img/shapeRight--salmon30.svg');
            }
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 0 1 auto;
            position: relative;
            padding: 10px;
        }

        &__title {
            @include textstyle(h5);
        }

        &__value {
            align-self: center;
            margin-top: 10px;
            text-align: center;

            &--large {
                @include textstyle(h1);
            }

            &--small {
                @include textstyle(h2);
            }
        }
    }

    .wp-block-file {
        display: flex;
        align-items: center;

        &__textlink {
            color: $purple;

            :global(.Theme--Arkitekturgalan) & {
                color: $arkitekturgalan-link;
            }
        }

        &__button {
            @include button(tertiary download);

            margin-left: 16px;
            color: $purple !important;
            text-decoration: none !important;
            opacity: 1 !important;
            transition: background $transition !important;

            :global(.Theme--Arkitekturgalan) & {
                color: $arkitekturgalan-link !important;
            }

            &:hover {
                color: $purple !important;
                text-decoration: none;

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-link !important;
                }
            }
        }
    }

    .wp-block-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .wp-block-button {
        margin: 0;
        display: block;

        &__link {
            @include button(tertiary);

            &:hover {
                text-decoration: none;
            }

            @include href-external {
                @include button(tertiary external);
            }

            @include href-download {
                @include button(tertiary download);

                // Make sure external icon isn't shown if link is both
                &::after {
                    display: none !important;
                }
            }
        }
    }

    // Support both gutenberg block and classic editor gallery shortcode
    .wp-block-gallery,
    .gallery {
        &.gallery,
        .blocks-gallery-grid {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin-left: -16px;
        }

        ul {
            margin: 0;
        }

        li {
            padding: 0; 
            
            &::before {
                display: none;
            }
        }
    
        img {
            width: 100%;
        }

        .gallery-item,
        .blocks-gallery-item {
            margin: 0 0 16px 16px;
            width: calc(50% - 16px);
            flex: 0 0 auto;

            @include media(M) {
                width: calc(25% - 16px);
            }
        }
        &.gallery-columns-1 .gallery-item,
        &.columns-1 .blocks-gallery-item {
            width: calc(100% - 16px);
        }
        &.gallery-columns-2 .gallery-item,
        &.columns-2 .blocks-gallery-item {
            width: calc(50% - 16px);
        }
        &.gallery-columns-3 .gallery-item,
        &.columns-3 .blocks-gallery-item {
            width: calc(#{percentage(math.div(1, 3))} - 16px);
        }
    }

    .wp-block-table {
        position: relative;
        overflow-x: unset;

        &.aligncenter {
            margin-left: auto;
            margin-right: auto;
        }

        &.alignleft {
            float: none;
            display: block;
            margin-right: auto;
        }

        &.alignright {
            float: none;
            display: block;
            margin-left: auto;
        }

        &.alignwide,
        &.alignfull {
            max-width: 100%;
            margin-left: 16px !important;
            margin-right: 16px !important;
        }

        table {
            width: 100%;

            &.has-fixed-layout {
                table-layout: fixed;
            }
        }

        td,
        th {
            padding: 10px;
            text-align: left;
            border: none;
            border-top: 1px solid $grey-30;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &.has-text-align-right {
                text-align: right;
            }

            &.has-text-align-center {
                text-align: center;
            }
        }

        thead {
            border: none; // For editor

            td,
            th {
                color: $heading-color;
                font-weight: $bold;
                border-top: none;
                border-bottom: 2px solid $salmon-40;
            }
        }

        // When there isn't a tfoot after
        tbody:last-child {
            tr:last-child {
                td,
                th {
                    border-bottom: 1px solid $grey-30;
                }
            }
        }

        tfoot {
            border: none; // For editor

            td {
                color: $heading-color;
                border-color: $salmon-40;
            }
        }
    }

    .block-editor-block-list__block {
        ul {
            list-style: none;
        }
    }
    
    .wp-block[data-align="left"] > .wp-block-image,
    .alignleft {
        display: inline;
        float: left;
        margin-right: 16px;
    }

    .wp-block[data-align="right"] > .wp-block-image,
    .alignright {
        display: inline;
        float: right;
        margin-left: 16px;
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .alignwide,
    .alignfull {
        // width: 100vw;
        position: relative;
        // margin-left: -#{$gutter};
        z-index: $z-RichtextImageFull;

        img {
            width: 100%;
        }

        figcaption {
            margin-left: $gutter;
        }

        $sl-maxwidth: map-get($maxwidths, SL);
        $sl-viewport-spacing: '(100vw - #{$sl-maxwidth}) / 2';
        $m-maxwidth: map-get($maxwidths, M);
        $m-viewport-spacing: '(100vw - #{$m-maxwidth}) / 2';
        $ml-maxwidth: map-get($maxwidths, ML);
        $ml-viewport-spacing: '(100vw - #{$ml-maxwidth}) / 2';
        $l-maxwidth: map-get($maxwidths, L);
        $l-viewport-spacing: '(100vw - #{$l-maxwidth}) / 2';
        $xl-maxwidth: map-get($maxwidths, XL);
        $xl-viewport-spacing: '(100vw - #{$xl-maxwidth}) / 2';
        $xxl-maxwidth: map-get($maxwidths, XXL);
        $xxl-viewport-spacing: '(100vw - #{$xxl-maxwidth}) / 2';

        .Article--IsLeft & {
        //     width: calc(100vw - #{$gutter});
            
        //     @include media(SL) {
        //         width: calc(100% + #{$gutter} + (#{$sl-viewport-spacing}));
        //         margin-left: calc((#{$sl-viewport-spacing}) * -1);
        //     }

        //     @include media(M) {
        //         width: calc(100% + 40px + (#{$m-viewport-spacing}));
        //         margin-left: calc((#{$m-viewport-spacing}) * -1);
        //     }

        //     @include media(ML) {
        //         width: calc(100% + 40px + (#{$ml-viewport-spacing}));
        //         margin-left: calc((#{$ml-viewport-spacing}) * -1);
        //     }

        //     @include media(L) {
        //         width: calc(100% + 104px + (#{$l-viewport-spacing}));
        //         margin-left: calc((#{$l-viewport-spacing}) * -1);
        //     }

        //     @include media(XL) {
        //         width: calc(100% + 104px + (#{$xl-viewport-spacing}));
        //         margin-left: calc((#{$xl-viewport-spacing}) * -1);
        //     }

        //     @include media(XXL) {
        //         width: calc(100% + 104px + (#{$xxl-viewport-spacing}));
        //         margin-left: calc((#{$xxl-viewport-spacing}) * -1);
        //     }

            figcaption {
                @include media(M) {
                    margin-left: calc(#{$m-viewport-spacing});
                }

                @include media(ML) {
                    margin-left: calc(#{$ml-viewport-spacing});
                }

                @include media(L) {
                    margin-left: calc(#{$l-viewport-spacing});
                }

                @include media(XL) {
                    margin-left: calc(#{$xl-viewport-spacing});
                }

                @include media(XXL) {
                    margin-left: calc(#{$xxl-viewport-spacing});
                }
            }
        }

        .Article--IsRight & {
            // width: calc(100vw - #{$gutter});
            
            // @include media(SL) {
            //     width: calc(100% + #{$gutter} + (#{$sl-viewport-spacing}));
            // }

            // @include media(M) {
            //     width: calc(100% + 40px + (#{$m-viewport-spacing}));
            //     margin-left: -40px;
            // }

            // @include media(ML) {
            //     width: calc(100% + 40px + (#{$ml-viewport-spacing}));
            // }

            // @include media(L) {
            //     width: calc(100% + 95px + (#{$l-viewport-spacing}));
            //     margin-left: -95px;
            // }

            // @include media(XL) {
            //     width: calc(100% + 95px + (#{$xl-viewport-spacing}));
            // }

            // @include media(XXL) {
            //     width: calc(100% + 95px + (#{$xxl-viewport-spacing}));
            // }

            figcaption {
                @include media(M) {
                    margin-left: 40px;
                }

                @include media(L) {
                    margin-left: 95px;
                }
            }
        }

        .Base--Longread & {
            // $longread-ml-maxwidth: 1000px;
            // $longread-ml-viewport-spacing: '(100vw - #{$longread-ml-maxwidth}) / 2';

            // @include media(SL) {
            //     margin-left: calc((#{$sl-viewport-spacing}) * -1);
            // }

            // @include media(M) {
            //     margin-left: calc((#{$m-viewport-spacing}) * -1);
            // }

            // @include media(ML) {
            //     width: calc(100% + (#{$longread-ml-viewport-spacing}));
            //     margin-left: calc((#{$longread-ml-viewport-spacing}) * -1);
            // }

            // @include media(L) {
            //     width: calc(100% + 90px + (#{$l-viewport-spacing}));
            //     margin-left: calc(((#{$l-viewport-spacing}) + 90px) * -1);
            // }

            // @include media(XL) {
            //     width: calc(100% + 90px + (#{$xl-viewport-spacing}));
            //     margin-left: calc(((#{$xl-viewport-spacing}) + 90px) * -1);
            // }

            // @include media(XXL) {
            //     width: calc(100% + 90px + (#{$xxl-viewport-spacing}));
            //     margin-left: calc(((#{$xxl-viewport-spacing}) + 90px) * -1);
            // }

            figcaption {
                @include media(SL) {
                    margin-left: calc(#{$sl-viewport-spacing});
                }

                @include media(M) {
                    margin-left: calc(#{$m-viewport-spacing});
                }

                @include media(ML) {
                    margin-left: calc(#{$longread-ml-viewport-spacing});
                }

                @include media(L) {
                    margin-left: calc((#{$l-viewport-spacing}) + 90px);
                }

                @include media(XL) {
                    margin-left: calc((#{$xl-viewport-spacing}) + 90px);
                }

                @include media(XXL) {
                    margin-left: calc((#{$xxl-viewport-spacing}) + 90px);
                }
            }
        }
    }

    figure {
        &.alignleft,
        &.alignright {
            margin-bottom: 8px;
        }
    }

    figcaption {
        @include textstyle(helptext);

        margin-top: 8px;
        display: block !important;
        text-align: left;
    }

    .wp-caption {
        max-width: 100%;
    }

    .wp-caption-text {
        color: rgba(black, 0.8);
        @include textstyle(body-small);
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .iframe {
        width: 100%;
        padding-bottom: percentage(math.div(9, 16));
        height: 0;
        position: relative;

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    iframe {
        max-width: 100%;
        display: block;
    }

    @media print {
        p,
        h2,
        h3,
        h4,
        h5,
        h6,
        img,
        iframe,
        .wp-caption,
        .gallery-item,
        .wp-caption-text {
            page-break-before: auto;
            page-break-after: auto;
            page-break-inside: avoid;
            display: block;
            position: relative; 
        }
    }
}